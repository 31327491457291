.App {
  text-align: center;
  background: black;
  background-color: black;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 3s linear;
  }
}

.App-header {
  width: 100%;
  background: rgb(0, 0, 0);
  /*background-color: #282c34;*/
  /*min-height: 20vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}

.link {
  color: rgb(0, 217, 255);
}

.link:hover {
   color: rgb(0, 165, 194);
}

.App-link {
  color: #61dafb;
}

.main-content {
  width: 80%;
  margin: 2rem 0% 0 0%;
  /*height: 150vh;*/
  color : white;
}

.copyrightgb {
  background-color: #000000;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: aliceblue;
}

@keyframes App-logo-spin {
  10% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(1deg);
  }
  30% {
    transform: rotate(-1deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0.5deg);
  }
  60% {
    transform: rotate(0.5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0.25deg);
  }
  90% {
    transform: rotate(0.25deg);
  }
}
